<template>
  <div class="router-wrap">
    <div class="searchdetail">

      <!--      <img class="aboutTop" :src="require('../assets/imgs/searchdefail.jpg')" />-->
      <div class="aboutTop"></div>

      <div class="searchdetailval" v-if="!istrue">
        <Back
            :name="langTrue == 'en-us' ? 'Search results' : '搜索结果'"
            backtop="25px"
            backright="40px"
            homeBack="/HomePage"
            :homeName="langTrue == 'en-us' ? 'home page' : '首页'"
        />
        <div class="searchdetailvaltop">
          {{ langTrue == "en-us" ? "Search results:" : "搜索结果：" }}
          <div class="searchdetailvaltopjilu">
            {{ langTrue == "en-us" ? "No search record" : "暂无搜索记录" }}
          </div>
        </div>
        <NoData></NoData>
      </div>

      <div class="searchdetailval" v-if="istrue" v-loading="loading">
        <Back
            :name="langTrue == 'en-us' ? 'Search results' : '搜索结果'"
            backtop="25px"
            backright="40px"
            homeBack="/HomePage"
            :homeName="langTrue == 'en-us' ? 'home page' : '首页'"
        />
        <div class="searchdetailvaltop">
          {{ langTrue == "en-us" ? "Search results:" : "搜索结果：" }}
          <div class="searchdetailvaltopjilu">
            {{ `(${information})(${data.number})` }}
          </div>
        </div>
        <div
          class="searchdetailvalchild"
          v-for="(item, ind) in data.list"
          :key="ind"
          @click="toDetails(item.id)"
        >
          <div class="searchdetailvalchildurlbox">
            <img
              :src="baseURL + item.coverImage"
              alt=""
              class="searchdetailvalchildurl"
            />
          </div>
          <div class="searchdetailvalchildtitle" v-html="item.title"></div>
          <div class="searchdetailvalchildval" v-html="item.subTitle"></div>
          <div class="searchdetailvalchildtime">
            {{ item.publishTime.slice(0, 10) }}
          </div>
        </div>

        <!-- v-if="joblistlength!==0" -->

      </div>
      <div class="pagination" v-if="data.number > 0">
        <div class="pagination-first" @click="handleCurrentChange(1)">
          {{ langTrue == "en-us" ? "Home page" : "首页" }}
        </div>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="5"
            :prev-text="langTrue == 'en-us' ? 'previous page' : '上一页'"
            :next-text="langTrue == 'en-us' ? 'next page' : '下一页'"
            layout=" prev, pager, next"
            :total="data.number"
        >
        </el-pagination>
        <div class="pagination-last" @click="handleCurrentChange(total)">
          {{ langTrue == "en-us" ? "Last page" : "尾页" }}
        </div>
      </div>

      <Footer></Footer>
      <!-- 搜索详情 -->
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import NoData from "@/components/noData.vue";
import { search } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
import Back from "@/components/back";

export default {
  name: "BusinessDetails",
  data() {
    return {
      langTrue: sessionStorage.getItem("langType"),
      information:
        sessionStorage.getItem("langType") == "en-us"
          ? "News and information"
          : "新闻资讯",
      baseURL: baseURL,
      istrue: true,
      currentPage: 1,
      total:1,
      searchval: "",
      data: {
        number: 0,
        list: [],
      },
      loading:false,
    };
  },
  components: {
    Footer,
    NoData,
    Back
  },
  watch: {
    $route: function (val) {
      console.log(val);
      if (val.query.searchval) {
        this.searchval = val.query.searchval;
        // this.ruleTitle()
      }
      this.getData();
    },
  },

  created() {
    console.log(baseURL);
    if (this.$route.query.searchval) {
      this.searchval = this.$route.query.searchval;
      // this.ruleTitle()
    }
    this.getData();
  },
  methods: {
    getAboutHeader(data) {
      this.topItem = data;
    },
    handleCurrentChange(val) {

      this.currentPage = val;
      console.log(val)
      console.log(this.currentPage)
      this.getData()
    },
    toDetails(id) {
      sessionStorage.setItem("newsItemDetailsId", id);
      this.$router.push("/NewsItemDetails");
    },
    getData() {
      this.data = {
        number: 0,
        list: [],
      };
      this.istrue = true;
      this.loading = true;
      search({
        keyword: this.searchval,
        lang: sessionStorage.getItem("langType"),
        pageSize: 5,
        pageNum: this.currentPage,
      }).then((res) => {
        console.log(res);
        this.loading = false;
        this.total = Math.ceil(res.total / 5);
        if (res.rows.length) {
          this.data = {
            number: res.total,
            list: res.rows,
          };
          this.ruleTitle();
        } else {
          this.istrue = false;
        }
      });
    },
    ruleTitle() {
      console.log("======");
      if (!this.searchval) return false;
      this.data.list.map((val, ind) => {
        if (this.searchval && this.searchval.length > 0) {
          // 匹配关键字正则
          let replaceReg = new RegExp(this.searchval, "g");
          // 高亮替换v-html值
          let replaceString =
            '<span style="color: #1e83d1;">' + this.searchval + "</span>";
          // 开始替换
          val.title = val.title.replace(replaceReg, replaceString);
          val.subTitle = val.subTitle.replace(replaceReg, replaceString);
        }
        return val;
      });
      console.log(this.data.list);
    },
  },
};
</script>
<style lang="scss" scoped>
.searchdetail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .aboutTop {
    width: 100%;
    height: 602px;
    background: url("../assets/imgs/searchdefail.jpg") no-repeat center center;
  }

  .searchdetailval {
    width: 1200px;
    min-height: 770px;
    position: relative;
    padding-top: 50px;
    // background: red;
    .searchdetailvaltop {
      width: 100%;
      height: 72px;
      border-bottom: 1px solid #dedede;
      display: flex;
      padding-top: 39px;
      font-size: 14px;
      color: #444444;
      font-weight: 600;

      .searchdetailvaltopjilu {
        color: #0072cb;
      }
    }

    .searchdetailvalchild {
      width: 100%;
      height: 175px;
      border-bottom: 1px dotted #dedede;
      cursor: pointer;
      .searchdetailvalchildurlbox {
        width: 218px;
        height: 115px;
        margin: 30px 20px 0 0;
        float: left;
        overflow: hidden;
      }
      .searchdetailvalchildurl {
        width: 218px;
        height: 115px;
        transition: transform 0.75s ease;
      }
      .searchdetailvalchildurl:hover {
        transform: scale(1.2, 1.2);
      }

      .searchdetailvalchildtitle {
        font-size: 16px;
        color: #060606;
        padding-top: 39px;
        margin-bottom: 18px;
        margin-left: 238px;
        width: 962px;
      }

      .searchdetailvalchildval {
        font-size: 14px;
        color: #060606;
        margin-bottom: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 238px;
        width: 962px;
      }

      .searchdetailvalchildtime {
        font-size: 14px;
        color: #aaaaaa;
        margin-left: 238px;
      }
    }
  }

  .pagination {
    //width: 100%;
    min-width: 1200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 85px 0;

    .pagination-first,
    .pagination-last {
      width: 82px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      cursor: pointer;
    }
  }
}
</style>
