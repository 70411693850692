import { render, staticRenderFns } from "./Searchdetails.vue?vue&type=template&id=a0a81fe2&scoped=true&"
import script from "./Searchdetails.vue?vue&type=script&lang=js&"
export * from "./Searchdetails.vue?vue&type=script&lang=js&"
import style0 from "./Searchdetails.vue?vue&type=style&index=0&id=a0a81fe2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0a81fe2",
  null
  
)

export default component.exports